import React, { Fragment } from 'react';
import AboutUsText from './aboutUsText';

import styles from './aboutUsMobile.module.scss';

function AboutUsMobile() {

    return (
        <div className={styles["about-us-mobile-container"]}>
            <AboutUsText />
        </div >
    );
}

export default AboutUsMobile
