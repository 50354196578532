import { Paper } from "@mui/material";
import React from "react";

function AboutUsText() {
  // return (
  //     <>
  //         <p>Wir sind ein junges Familienunternhemen, dass durch über 20 Jahre Erfahrung im Reinigungsservice die Wünsche und Bedürfnisse Ihrer Kunden erfüllt.</p>
  //         <p>Als Gebäudereinigungsunternehmen bieten wir Ihnen alle Wesentlichen Leistungen des Reinigungsservices an. Von der Grundreinigung bis hin zur regelmäßigen Unterhaltsreinigung von Büros, Arztpraxen und weiteren Einrichtungen,
  //             kümmern wir uns auch um die Fensterreinigung, sowie um die Treppenhausreinigung. Unsere zuverlässige Büroreinigung, Praxisreinigung und Fensterreinigung sorgt für einen sauberen und aufgeräumten
  //             Raum, damit nach Abschluss unserer Reinigung alle Verschmutzungen entfernt sind</p>
  //         <p>Professionelle und schonende Gebäudereinigung trägt zu einer angenehmen Arbeitsatmosphäre bei und ist somit ein wesentlicher
  //             Bestandteil Ihres Erfolgs. Gut erhaltene Gebäude und Büros sind die Grundzüge der Firmeneinführung. Sie hinterlassen bei Ihren
  //             Gästen und Geschäftspartner einen positiven Eindruck. Neben sauberen Arbeitsplätzen, Besprechungsräumen und Fluren
  //             gilt es natürlich auch besonders im Sanitär und Küchenbereich auf Hygiene zu Achten.
  //             Diese Dienstleistungen werden von uns im Rahmen der Büroreinigung gründlich durchgeführt.
  //             Zusätzlich bieten wir mit der Fensterreinigung ergänzenden Reinigungsservice an.</p>
  //         <p>Wir helfen Ihnen gerne dabei, ein sauberes und gepflegtes Arbeitsumfeld zu schaffen und bieten Ihnen vielfältige Dienstleistungen an.
  //             Wir beschränken uns nicht nur auf die Gebäude und Büroreinigung, sondern bieten auch Praxisreinigung und Kitareinigung an.</p>
  //         <p>Lassen Sie sich gerne über unseren Reinigungsservice in Hamburg beraten.
  //             Rufen Sie uns an, unter 040 - 51 90 72 99 oder nutzen Sie das Kontaktformular um mit uns in Kontakt zu treten.</p>

  //     </>
  // );
  return (
    <>
      <h2>
        Herzlich willkommen bei Musliu Reinigung – Ihrem zuverlässigen
        Reinigungsservice in Hamburg!
      </h2>
      <p>Ihr professioneller Partner für Sauberkeit und Hygiene</p>
      <p>
        Entdecken Sie die herausragenden Reinigungsdienstleistungen von Musliu
        Reinigung und verleihen Sie Ihrem Zuhause, Büro, Praxis, Kindergarten
        oder Treppenhaus strahlende Sauberkeit. Als etablierter
        Reinigungsservice in Hamburg sind wir stolz darauf, unseren Kunden
        erstklassige Dienstleistungen anzubieten, die höchsten Ansprüchen an
        Qualität und Zuverlässigkeit gerecht werden.
      </p>
      <h2>Unsere Leistungen im Überblick:</h2>
      <h3>Grundreinigung für strahlende Räume</h3>
      <p>
        Musliu Reinigung steht für gründliche Grundreinigungen, die Ihren
        Räumlichkeiten neuen Glanz verleihen. Egal, ob nach einem Umzug, einer
        Renovierung oder einfach zur Auffrischung – wir beseitigen effektiv
        hartnäckigen Schmutz und sorgen für eine saubere Grundlage. Unsere
        professionellen Reinigungskräfte setzen modernste Reinigungstechniken
        und hochwertige Reinigungsmittel ein, um ein Höchstmaß an Sauberkeit zu
        gewährleisten.
      </p>
      <h3>Büroreinigung für eine produktive Arbeitsumgebung</h3>
      <p>
        Ein sauberes Büro ist nicht nur ein ästhetischer Mehrwert, sondern auch
        essentiell für das Wohlbefinden und die Produktivität Ihrer Mitarbeiter.
        Musliu Reinigung kümmert sich um die regelmäßige Reinigung Ihrer
        Büroflächen, inklusive Büromöbel, Böden, Sanitäranlagen und mehr. Wir
        passen unsere Reinigungspläne flexibel an Ihre Betriebszeiten an, um
        sicherzustellen, dass Ihre Arbeitsabläufe nicht beeinträchtigt werden.
      </p>
      <h3>Hygienische Praxisreinigung für Ihr Wohl und das Ihrer Patienten</h3>
      <p>
        In medizinischen Einrichtungen steht Hygiene an oberster Stelle. Unsere
        spezialisierte Praxisreinigung sorgt für eine makellose Umgebung, in der
        sich Patienten wohlfühlen und Ärzte ungestört arbeiten können. Wir
        berücksichtigen die speziellen Anforderungen im Gesundheitswesen und
        setzen auf Desinfektionsmittel und Reinigungsmethoden, die höchsten
        Standards entsprechen.
      </p>
      <h3>Kindergartenreinigung für eine sichere Spielumgebung</h3>
      <p>
        Kinder sind besonders empfindlich, wenn es um Sauberkeit geht. Unsere
        Kindergartenreinigung konzentriert sich darauf, eine hygienische
        Umgebung zu schaffen, in der die Kleinen sicher spielen und lernen
        können. Wir verwenden schonende Reinigungsmittel, um den Bedürfnissen
        der Kinderhaut gerecht zu werden, und stellen sicher, dass Spielzeug und
        Räume regelmäßig gründlich gereinigt werden.
      </p>
      <h3>Treppenhausreinigung für einen gepflegten Eingangsbereich</h3>
      <p>
        Das Treppenhaus ist oft der erste Eindruck eines Gebäudes. Musliu
        Reinigung legt großen Wert auf eine regelmäßige Treppenhausreinigung, um
        einen positiven Eindruck bei Bewohnern und Besuchern zu hinterlassen.
        Wir entfernen nicht nur sichtbaren Schmutz, sondern kümmern uns auch um
        schwer erreichbare Stellen und sorgen für eine allumfassende Reinigung.
      </p>
      <h3>Klarer Durchblick mit unserer Fensterreinigung</h3>
      <p>
        Für klare Durchblicke setzen wir auf unsere professionelle
        Fensterreinigung. Schmutz, Staub und Fingerabdrücke haben keine Chance –
        unsere erfahrenen Reinigungskräfte hinterlassen Ihre Fenster makellos.
        Egal, ob es sich um kleine Fensterfronten oder große Glasflächen
        handelt, wir garantieren eine gründliche und streifenfreie Reinigung.
      </p>

      <h2>Warum Musliu Reinigung?</h2>
      <h3>1. Erfahrung</h3>
      <p>
        Mit jahrelanger Erfahrung in der Reinigungsbranche sind wir Experten auf
        unserem Gebiet. Unsere Mitarbeiter verfügen über das notwendige
        Know-how, um selbst anspruchsvollste Reinigungsaufgaben zu bewältigen.
      </p>
      <h3>2. Zuverlässigkeit</h3>
      <p>
        Pünktlichkeit und Verlässlichkeit sind für uns selbstverständlich. Wir
        halten uns an vereinbarte Termine und gewährleisten eine zuverlässige
        Ausführung unserer Dienstleistungen.
      </p>
      <h3>3. Qualitätsanspruch</h3>
      <p>
        Wir setzen auf qualitativ hochwertige Reinigungsmittel und moderne
        Reinigungstechniken. Unser Qualitätsanspruch spiegelt sich in der
        Sauberkeit und Frische wider, die wir in Ihren Räumlichkeiten
        hinterlassen.
      </p>
      <h3>4. Kundenzufriedenheit</h3>
      <p>
        Die Zufriedenheit unserer Kunden hat oberste Priorität. Wir gehen auf
        Ihre individuellen Anforderungen ein und arbeiten eng mit Ihnen
        zusammen, um sicherzustellen, dass unsere Dienstleistungen Ihren
        Erwartungen entsprechen.
      </p>

      <h2>Kontaktieren Sie uns für ein unverbindliches Angebot</h2>
      <p>
        Musliu Reinigung ist Ihr vertrauenswürdiger Partner für
        Reinigungsdienstleistungen in Hamburg. Kontaktieren Sie uns noch heute
        für ein unverbindliches Angebot und lassen Sie sich von unserer
        Professionalität überzeugen. Wir freuen uns darauf, Ihnen
        maßgeschneiderte Lösungen für ein sauberes und gepflegtes Umfeld zu
        bieten.
      </p>
      <p>Musliu Reinigung – Ihre Wahl für Sauberkeit in Hamburg!</p>
    </>
  );
}

export default AboutUsText;
