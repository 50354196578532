import React from 'react';
import AboutUsText from './aboutUsText';

import styles from './aboutUsDesktop.module.scss';

function AboutUsDesktop() {

    return (
        <div className={styles["about-us-desktop-container"]}>
            <AboutUsText />
        </div >
    );
}

export default AboutUsDesktop
