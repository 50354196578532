import React from "react";
import AboutUsDesktop from "./aboutUsDesktop";
import AboutUsMobile from "./aboutUsMobile";

import styles from "./aboutUs.module.scss";

import { usePathname } from "next/navigation";
import { Routes } from "../../helperFunctions/routes";

function AboutUs() {
  const RenderHeadline = () => {
    const pathname = usePathname();
    return pathname === Routes.UeberUns ? (
      <h1 className={styles["about-us-title"]}>Über uns</h1>
    ) : (
      <p className={styles["about-us-title"]}>Über uns</p>
    );
  };

  return (
    <div
      className={`component-layout component-layout-border ${styles["about-us-container"]}`}
    >
      {RenderHeadline()}
      <AboutUsMobile />
      <AboutUsDesktop />
    </div>
  );
}

export default AboutUs;
